export const TourFormAllowedFields = [
  "transport_agent_id",
  "name",
  "description",
  "active",
  "is_default",
  "third_party",
  "delivery_timings",
  "allowed_supplier_locations",
  "supplier_location_id",
  "user_defined_tour_id",
];

export const CustomerFormAllowedFields = [
  "number",
  "tour_id",
  "name",
  "street",
  "street_number",
  "city",
  "zipcode",
  "country",
  "email",
  "phone",
  "latitude",
  "longitude",
  "deposit_agreement",
  "keybox_code",
  "contact_name",
  "contact_surname",
  "contact_salutation",
  "email_notifications",
  "active",
  "tour_manually_assigned",
  "priority",
  "supplier_id",
  "duplicate",
  "formatted_address",
  "default_remarks",
];

export const OrderFormAllowedFields = [
  "customer_id",
  "description",
  "number",
  "departure",
  "packages",
  "departure_date",
  "remarks",
  "express",
  "supplier_location_id",
];
export const SupplierLocationFormAllowedFields = [
  "name",
  "street",
  "street_number",
  "city",
  "zipcode",
  "country",
  "email",
  "phone",
  "latitude",
  "longitude",
  "supplier_id",
  "formatted_address",
];
export const ZipcodeFormAllowedFields = ["zipcode", "tour_id"];
