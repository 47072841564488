import { createSlice } from "@reduxjs/toolkit";
import { coreApi } from "api/core";
import { setShowMessage } from "redux/slices/uiSlice";

const initialState = {
  importing: false,
  error: null,
  success: false,
  importedCount: 0,
  importErrors: {},
  message: "",
};

export const importCustomersFile = (file) => async (dispatch) => {
  dispatch(importStart());

  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await coreApi.post("/import/customers", formData, {

      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Success case (status 200)

    // ONLY show toast for success
    if (response.success) {
      dispatch(
        setShowMessage({
          description: "Customers imported successfully",
          type: "success",
        })
      );
    }

    dispatch(importSuccess(response.data));
    return response;
  } catch (err) {
    const errorData = err.response?.data || {
      success: false,
      imported: 0,
      errors: {},
      message: "Error importing customers",
    };

    dispatch(importSuccess(errorData));
    return errorData;
  }
};

// Rest of the slice definition remains the same
const customersImportSlice = createSlice({
  name: "customersImport",
  initialState,
  reducers: {
    importStart: (state) => {
      state.importing = true;
      state.error = null;
      state.success = false;
      state.importedCount = 0;
      state.importErrors = {};
      state.message = "";
    },
    importSuccess: (state, action) => {
      state.importing = false;
      state.success = action.payload.success || false;
      state.error = null;
      state.importedCount = action.payload.imported || 0;
      state.importErrors = action.payload.errors || {};
      state.message = action.payload.message || "";
    },
    importFailed: (state, action) => {
      state.importing = false;
      state.error = action.payload;
      state.success = false;
    },
    resetImportState: (state) => {
      state.importing = false;
      state.error = null;
      state.success = false;
      state.importedCount = 0;
      state.importErrors = {};
      state.message = "";
    },
  },
});

export const { importStart, importSuccess, importFailed, resetImportState } = customersImportSlice.actions;
export default customersImportSlice.reducer;
