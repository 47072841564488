import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { CUSTOMERS_TABLE_COLUMNS, CUSTOMERS_TABLE_COLUMNS_ADMIN } from "constants/ui-constants";
import { getColumns, getActions, getLocalization } from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import {
  selectCustomers,
  selectCustomerStatus,
  deleteCustomer,
  exportCustomers,
  getCustomers,
} from "redux/slices/customerSlice";
import withConfirm from "components/dialogs/delete";
import Navbar from "components/Navbar";
import CustomersNavbar from "components/Masterbar/CustomersBar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";
import ImportCustomersModal from "components/CustomerModal/ImportCustomersModal";

// Using MUI's styled components to define the FilterIcon
const FilterIcon = styled("i")(({ theme }) => ({
  color: "#525252",
  fontSize: "12px",
}));

const tableTitle = "CUSTOMERS";

const CustomersList = ({ confirm }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(selectCustomerStatus);
  const customers = useSelector(selectCustomers);
  const user = useSelector(selectUser);
  const [importModalOpen, setImportModalOpen] = useState(false);

  useEffect(() => {
    if (!user) return;

    if (user.permissions?.customers?.admin) {
      dispatch(getCustomers(true, { where: { duplicate: false } }));
    } else {
      dispatch(
        getCustomers(true, {
          where: {
            supplier_id: user.supplier_id,
            duplicate: false,
          },
        })
      );
    }
  }, [dispatch, user]);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();
    confirm(() => dispatch(deleteCustomer(rowData.id)), {
      description: "Are you sure?",
    });
  };

  // Handle filters for export functionality
  let filters;
  const formatKey = (key) => {
    return key
      .split(".")
      .map((part, index) => {
        if (index > 0) {
          return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
        return part.toLowerCase();
      })
      .join("");
  };
  const getFilteredData = (filterState) => {
    filters = filterState.reduce((acc, filter) => {
      const formattedKey = formatKey(filter.column.field);
      acc[formattedKey] = filter.value;
      return acc;
    }, {});
  };
  const exportFile = () => {
    const language = i18n.language === "de" ? "de" : "en";
    dispatch(exportCustomers(language, filters));
  };
  const handleImportCustomers = () => {
    setImportModalOpen(true); 
  };

  const handleImportComplete = () => {
    if (user?.permissions?.customers?.admin) {
      dispatch(getCustomers(true, { where: { duplicate: false } }));
    } else {
      dispatch(
        getCustomers(true, {
          where: {
            supplier_id: user.supplier_id,
            duplicate: false,
          },
        })
      );
    }
  };

  const actions = getActions(
    tableTitle,
    callbackOnDelete,
    () => history.push(PATHS.customers.add),
    null,
    null,
    null,
    t,
    user,
    exportFile,
    handleImportCustomers
  );
  const customerTableColumns = () => {
    if (user?.permissions?.customers?.admin) {
      return CUSTOMERS_TABLE_COLUMNS_ADMIN(t);
    }
    return CUSTOMERS_TABLE_COLUMNS(t);
  };

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <DarkLayout doublebar loading={loading}>
        <div className="custom-table-styles">
          <MaterialTable
            icons={{
              Filter: () => <FilterIcon className={clsx("fas fa-filter")} />,
            }}
            style={{ display: "flex", flexDirection: "column" }}
            data={mapTableData(customers)}
            title={t(tableTitle)}
            columns={getColumns(customerTableColumns(), t)}
            onRowClick={(e, rowData) => history.push(PATHS.customers.detail.replace(":id", rowData.id))}
            actions={actions}
            localization={getLocalization(t)}
            onFilterChange={getFilteredData}
            options={{
              pageSize: 50,
              pageSizeOptions: [50, 100],
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              filtering: true,
              sorting: true,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#121212",
                color: "white",
                borderBottom: "1px solid #525252",
                font: "normal normal normal 12px/24px Roboto",
                fontWeight: "bold",
              },
              cellStyle: {
                backgroundColor: "#121212",
                color: "white",
                border: "none",
                font: "normal normal normal 12px/24px Roboto",
                padding: "0 16px",
              },
              searchFieldStyle: {
                color: "#F5F5F5",
              },
              filterCellStyle: {
                color: "#F5F5F5",
              },
              rowStyle: { height: "38px" },
            }}
          />
          <ImportCustomersModal
            open={importModalOpen}
            onClose={() => setImportModalOpen(false)}
            t={t}
            onImportComplete={handleImportComplete}
          />
        </div>
      </DarkLayout>
    </>
  );
};

export default withConfirm(CustomersList);
