import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  Box,
  Link,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Slide } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../redux/store/actions/alerts";
import { importCustomersFile, resetImportState } from "../../redux/slices/customersImportSlice";

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImportCustomersModal = ({ open, onClose, t, onImportComplete }) => {
  const dispatch = useDispatch();
  const fileInputRef = React.useRef(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [localErrors, setLocalErrors] = useState({});

  const {
    importing: isUploading,
    error: importError,
    success,
    importedCount,
    importErrors,
    message,
  } = useSelector((state) => state.customersImport);

  useEffect(() => {
    if (!open) {
      dispatch(resetImportState());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(
        showMessage({
          message: t(message),
          type: "success",
        })
      );

      if (importedCount > 0) {
        if (onImportComplete) onImportComplete();
      }

      if (!importErrors || Object.keys(importErrors).length === 0) {
        setTimeout(() => {
          setUploadProgress(0);
          setFile(null);
          onClose();
        }, 1000);
      }
    } else if (success === false && importErrors && Object.keys(importErrors).length > 0) {
      dispatch(
        showMessage({
          message: t("No customers were imported due to validation errors."),
          type: "error",
        })
      );

      setUploadProgress(0);
    }
  }, [success, message, importedCount, importErrors, dispatch, t, onClose, onImportComplete]);

  useEffect(() => {
    if (importError) {
      dispatch(
        showMessage({
          message: t(importError),
          type: "error",
        })
      );
    }
  }, [importError, dispatch, t]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    validateAndSetFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];
      validateAndSetFile(droppedFile);
    }
  };
const translateErrorMessage = (errorMsg) => {
  if (errorMsg.includes("Supplier") && errorMsg.includes("not found")) {
    const supplierName = errorMsg.match(/"([^"]+)"/)?.[1];
    if (supplierName) {
      return t("Supplier \"{{supplierName}}\" not found", { supplierName });
    }
  } else if (errorMsg.includes("Supplier location") && errorMsg.includes("not found")) {
    const locationMatch = errorMsg.match(/"([^"]+)"/g);
    if (locationMatch && locationMatch.length >= 2) {
      const location = locationMatch[0].replace(/"/g, '');
      const supplier = locationMatch[1].replace(/"/g, '');
      return t("Supplier location \"{{location}}\" not found for supplier \"{{supplier}}\"", 
               { location, supplier });
    }
  } else if (errorMsg.includes("Customer") && errorMsg.includes("at this address already exists")) {
    const customerName = errorMsg.match(/"([^"]+)"/)?.[1];
    if (customerName) {
      return t("Customer \"{{customerName}}\" at this address already exists", { customerName });
    }
  }
  
  return t(errorMsg);
};
  const validateAndSetFile = (selectedFile) => {
    const validExcelTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".xlsx",
      ".xls",
    ];

    const fileExtension = selectedFile?.name?.toLowerCase().split(".").pop();

    setLocalErrors({});
    setUploadProgress(0);
    dispatch(resetImportState());

    if (
      selectedFile &&
      (validExcelTypes.includes(selectedFile.type) || validExcelTypes.includes(`.${fileExtension}`))
    ) {
      setFile(selectedFile);
    } else {
      dispatch(
        showMessage({
          message: t("Please select a valid Excel file (.xls or .xlsx)"),
          type: "error",
        })
      );
      setFile(null);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setLocalErrors({});
    setUploadProgress(0);
    dispatch(resetImportState());
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImport = async () => {
    if (!file) {
      dispatch(
        showMessage({
          message: t("Please select an Excel file to import"),
          type: "warning",
        })
      );
      return;
    }

    const progressInterval = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 90) {
          clearInterval(progressInterval);
          return 90;
        }
        return prev + 10;
      });
    }, 300);

    try {
      const responseData = await dispatch(importCustomersFile(file));
      clearInterval(progressInterval);

      if (responseData?.errors) {
        setLocalErrors(responseData?.errors);
      }

      if (responseData?.success) {
        setUploadProgress(100);

        if (responseData.imported > 0 && onImportComplete) {
          onImportComplete();
        }
          onClose();
      } else {
        setUploadProgress(0);
      }
    } catch (error) {
      clearInterval(progressInterval);
      setUploadProgress(0);
      console.error("Import error:", error);
    }
  };

  const handleClose = () => {
    if (!isUploading) {
      setFile(null);
      onClose();
    }
  };

  const downloadTemplate = (e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = "assets/templates/Kundenimport Vorlage.xlsx";
    link.download = "Kundenimport Vorlage.xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const hasImportErrors = importErrors && Object.keys(importErrors).length > 0;

  return (
    <>
      <Backdrop
        open={open && isLoading}
        style={{
          zIndex: 1301,
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        TransitionComponent={SlideTransition}
        transitionDuration={{ enter: 500, exit: 300 }}
        aria-labelledby="import-customers-dialog-title"
      >
        <DialogTitle id="import-customers-dialog-title">
          {t("Import Customers")}
          {!isUploading && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "grey",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {t(
              "Upload an Excel file (.xlsx or .xls) containing customer data. The file should follow the required format."
            )}
          </DialogContentText>

          <Box mt={3} mb={2}>
            <Typography variant="subtitle2">
              {t("Download")}{" "}
              <Link href="#" onClick={downloadTemplate}>
                {t("Excel template")}
              </Link>
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            border="1px dashed #ccc"
            borderRadius={4}
            p={3}
            mt={2}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              accept=".xlsx, .xls"
              id="import-customers-file"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isUploading}
            />

            {!file ? (
              <Box textAlign="center" py={3}>
                <DescriptionIcon style={{ fontSize: 48, color: "#6F9CEB", marginBottom: 16 }} />
                <Typography variant="body1" gutterBottom>
                  {t("Drag and drop Excel file here")}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {t("or")}
                </Typography>
                <label htmlFor="import-customers-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    disabled={isUploading}
                  >
                    {t("Browse Files")}
                  </Button>
                </label>
                <Typography variant="caption" display="block" color="textSecondary" style={{ marginTop: 8 }}>
                  {t("Supported formats: .xlsx, .xls")}
                </Typography>
              </Box>
            ) : (
              <Box textAlign="center" py={2} width="100%">
                <Box display="flex" alignItems="center" justifyContent="center" mb={2} position="relative">
                  <DescriptionIcon style={{ color: "#6F9CEB", marginRight: 8 }} />
                  <Typography variant="body2">
                    {file.name} ({Math.round(file.size / 1024)} KB)
                  </Typography>
                  <IconButton
                    size="small"
                    style={{ position: "absolute", right: -10, top: -10 }}
                    onClick={handleRemoveFile}
                    disabled={isUploading}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>

                <label htmlFor="import-customers-file">
                  <Button size="small" color="primary" component="span" disabled={isUploading}>
                    {t("Change File")}
                  </Button>
                </label>

                {Object.keys(localErrors).length > 0 && (
                  <Box
                    mt={2}
                    p={2}
                    bgcolor="rgba(255, 0, 0, 0.05)"
                    border="1px solid #ffcdd2"
                    borderRadius={4}
                    textAlign="left"
                  >
                    <Typography variant="subtitle2" color="error" gutterBottom>
                      {t("Import errors:")}
                    </Typography>
                    <ul style={{ margin: 0, paddingLeft: 20 }}>
                      {Object.entries(localErrors).map(([row, error]) => (
                        <li key={row}>
                          <Typography variant="body2">
                            {t("Row")} {parseInt(row) + 1}: {translateErrorMessage(error)}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            )}

            {isUploading && (
              <Box mt={2} width="100%">
                <Typography variant="body2" align="center" gutterBottom>
                  {uploadProgress < 100
                    ? t("Uploading... {{progress}}%", { progress: uploadProgress })
                    : t("Processing data...")}
                </Typography>
                <LinearProgress variant="determinate" value={uploadProgress} color="primary" />
              </Box>
            )}
          </Box>

          {hasImportErrors && (
            <Box mt={3} p={2} bgcolor="rgba(255, 0, 0, 0.05)" border="1px solid #ffcdd2" borderRadius={4}>
              <Typography variant="subtitle2" color="error" gutterBottom>
                {success
                  ? t("Some rows could not be imported:")
                  : t("No customers were imported due to the following errors:")}
              </Typography>
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                {/* Add null check here */}
                {importErrors &&
                  Object.entries(importErrors).map(([row, error]) => (
                    <li key={row}>
                      <Typography variant="body2">
                        {t("Row")} {parseInt(row) + 1}: {error}
                      </Typography>
                    </li>
                  ))}
              </ul>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={isUploading}>
            {t("Cancel")}
          </Button>
          <Button onClick={handleImport} color="primary" variant="contained" disabled={!file || isUploading}>
            {t("Import")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportCustomersModal;
